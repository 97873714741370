import { Input, Modal, Spin, Upload } from '@pankod/refine-antd';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { FiSend, FiCamera } from 'react-icons/fi';
import io from 'socket.io-client';
import { LoadingOutlined } from '@ant-design/icons';
import {
  GetThreadAction,
  MarkMessagesAsReadAction,
  SendMessageAction,
} from '../../Actions/ConfigsActions';
import { Thread, ThreadMessages } from '../../types';
import { useGetIdentity } from '@pankod/refine-core';
import colors from '../../ Theme/ Colors';
import { GET_UPLOAD, SOCKET_URI, UPLOAD_URI } from '../../configs';
import { AccountStore, ConfigsStore } from '../../ Store';
import _ from 'lodash';
import noPhoto from '../../Assests/noProfilePhoto.png';
import gomintLogo from '../../Assests/gomintLogo.png';
import white from '../../Assests/white.png';
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info: Thread;
  thread_id: number;
  refresh?: () => void;
}
export default function ChatModal({
  visible,
  thread_id,
  setVisible,
  info,
}: Props) {
  const { data: admin } = useGetIdentity();

  const socket = io(SOCKET_URI);
  const bottom = useRef<null | HTMLDivElement>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [inputText, setInputText] = useState<string>();
  const [messages, setMessages] = useState<ThreadMessages[]>([]);

  const [headers, setHeaders] = useState<{ token: string; device_id: string }>({
    device_id: '',
    token: '',
  });

  const getHeaders = async () => {
    const device_id = await ConfigsStore.get('device_id');
    const account = await AccountStore.get('account');
    setHeaders({
      token: account?.tokens?.access_token || '',
      device_id: device_id || '',
    });
  };
  const formatMessageTime = (createdAt: string): string => {
    const messageDate = new Date(createdAt);
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };
    return messageDate.toLocaleTimeString([], options);
  };

  const getMessages = (value: any) => setMessages(value);

  const handleGetThread = useCallback(() => {
    GetThreadAction(thread_id, getMessages);
  }, [thread_id]);

  const addNewMessage = (message: ChangeEvent<HTMLInputElement>) => {
    setInputText(message?.target?.value);
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const handleSubmit = () => {
    if (inputText && !loading) {
      startLoading();
      SendMessageAction(
        {
          body: inputText,
          thread_id,
          type: 'TEXT',
          sender_id: admin?.id,
        },
        handleGetThread,
        stopLoading
      );
      setInputText('');
      scrollToBottom();
    }
  };

  const handleUploadImageSubmit = (file: any) => {
    const imgId = file && file[0]?.id;
    if (!loading && !imgId) return;
    startLoading();
    SendMessageAction(
      {
        body: '',
        thread_id,
        type: 'IMAGE',
        sender_id: admin?.id,
        upload_id: imgId,
      },
      handleGetThread,
      stopLoading
    );
    setInputText('');
    scrollToBottom();
  };

  const scrollToBottom = () => {
    bottom?.current?.scrollIntoView();
  };

  useEffect(() => {
    isConnected && scrollToBottom();
  }, [isConnected, messages]);

  useEffect(() => {
    handleGetThread();
  }, [handleGetThread]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
      socket.on(`${thread_id}`, () => {
        handleGetThread();
      });
    });

    return () => {
      socket.off('connect');
    };
  }, [handleGetThread, socket, thread_id]);

  const handleCloseModal = () => {
    socket.on('disconnect', () => {
      setIsConnected(false);
    });
    socket.off('disconnect');
    setVisible('');
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (inputText) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (messages?.length > 0) {
      MarkMessagesAsReadAction(
        messages?.filter((item) => !item?.is_read).map((item) => item?.id)
      );
    }
  }, [messages]);

  useEffect(() => {
    getHeaders();
  }, []);
  const reorderMessages = _.map(
    _.sortBy(messages, (o) => Date.parse(o.created_at))
  );
  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      width={650}
      closable
      footer={null}
      cancelText={'Close'}
      okText={'Send'}
      bodyStyle={{ height: 585 }}
    >
      <Container>
        {isConnected ? (
          <div style={{ width: '100%' }}>
            <ChatContainer>
              <MessagesWrapper className="messages">
                {messages?.length === 0 && (
                  <NoMessages>{'No messages were found'}</NoMessages>
                )}
                {messages?.length === 0 && loading && (
                  <SpinWrapper>
                    <Spin indicator={antIcon} />
                  </SpinWrapper>
                )}
                {reorderMessages?.map((item, key) => (
                  <>
                    {info?.first_participant_id === item?.sender_id ||
                    info?.id === item?.sender_id ? (
                      <LeftContainer key={key}>
                        <InnerLeftMessageWrapper>
                          {key === reorderMessages.length - 1 ||
                          reorderMessages[key + 1].sender_id !==
                            item.sender_id ? (
                            <UserWrapper>
                              <UserImg
                                src={
                                  info?.first_participant?.upload_id
                                    ? `${GET_UPLOAD}/${info?.first_participant?.upload_id}`
                                    : info?.uplaod?.id
                                    ? `${GET_UPLOAD}/${info?.uplaod?.id}`
                                    : noPhoto
                                }
                                alt={'user'}
                              />
                              {/* <SenderName>{item?.sender?.name}</SenderName> */}
                            </UserWrapper>
                          ) : (
                            <UserWrapper>
                              <UserImg src={white} alt={'user'} />
                              {/* <SenderName white>{item?.sender?.name}</SenderName> */}
                            </UserWrapper>
                          )}
                          {item?.type === 'TEXT' && (
                            <LeftBubble>
                              {item?.body}{' '}
                              <MessageTime isLeftBubble>
                                {formatMessageTime(item.created_at)}
                              </MessageTime>
                            </LeftBubble>
                          )}
                          {item?.type === 'IMAGE' && (
                            <a
                              href={`${GET_UPLOAD}/${item?.upload_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <ChatImg
                                src={`${GET_UPLOAD}/${item?.upload_id}`}
                                alt={'img'}
                              />
                            </a>
                          )}
                        </InnerLeftMessageWrapper>
                      </LeftContainer>
                    ) : (
                      <RightContainer key={key}>
                        <InnerRightMessageWrapper>
                          {key === reorderMessages.length - 1 ||
                          reorderMessages[key + 1].sender_id !==
                            item.sender_id ? (
                            <UserWrapper left>
                              <UserImg src={gomintLogo} alt={'user'} />
                              {/* <SenderName>{item?.sender?.name}</SenderName> */}
                            </UserWrapper>
                          ) : (
                            <UserWrapper left>
                              <UserImg src={white} alt={'user'} />
                              {/* <SenderName white>{item?.sender?.name}</SenderName> */}
                            </UserWrapper>
                          )}
                          {item?.type === 'TEXT' && (
                            <div style={{ width: '70%' }}>
                              <RightBubble>
                                {item?.body}
                                <MessageTime>
                                  {formatMessageTime(item.created_at)}
                                </MessageTime>
                              </RightBubble>
                            </div>
                          )}
                          {item?.type === 'IMAGE' && (
                            <a
                              href={`${GET_UPLOAD}/${item?.upload_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <ChatImg
                                src={`${GET_UPLOAD}/${item?.upload_id}`}
                                alt={'img'}
                              />
                            </a>
                          )}
                        </InnerRightMessageWrapper>
                      </RightContainer>
                    )}
                  </>
                ))}
                <div ref={bottom} />
              </MessagesWrapper>
              <InputContainer onSubmit={onFormSubmit}>
                <Upload.Dragger
                  name="file"
                  action={UPLOAD_URI}
                  listType="picture"
                  accept="image/*"
                  showUploadList={false}
                  maxCount={1}
                  style={{ border: 'none', background: 'none' }}
                  onChange={(file: any) =>
                    handleUploadImageSubmit(file?.file?.response)
                  }
                  headers={{
                    authorization: `Bearer ${headers?.token}`,
                    device_id: headers.device_id,
                  }}
                >
                  <FiCamera
                    size={26}
                    color={colors.blue100}
                    className="icons"
                    style={{ marginTop: 3 }}
                  />
                </Upload.Dragger>

                <Input
                  placeholder="Type your message here"
                  style={{ width: '80%' }}
                  value={inputText}
                  onChange={addNewMessage}
                />
                <SendButton className="icons" onClick={handleSubmit}>
                  <FiSend size={20} color={'#fff'} />
                </SendButton>
              </InputContainer>
            </ChatContainer>
          </div>
        ) : (
          <SpinWrapper>
            <Spin indicator={antIcon} />
          </SpinWrapper>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const NoMessages = styled.div`
  position: absolute;
  top: 40%;
  left: 37%;
  font-weight: bold;
  size: 35px;
`;

const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  border: 1px gray solid;
  border-radius: 5px;
  height: 520px;
  flex-direction: column;
  padding-bottom: 5px;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: baseline;
  justify-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 20px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  margin-top: 5px;
  margin-right: 20px;
`;

const LeftBubble = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
  align-self: flex-start;
  margin-right: 10px;
  margin-top: 8px;
  min-height: 50px;
  border-radius: 12px;
  background-color: ${colors.neutral1000};
  font-size: 14px;
  color: ${colors.darkGrey};
  padding: 10px 15px;
  font-weight: 500;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent ${colors.neutral1000} transparent transparent;
    transform: translateY(-50%);
  }
`;

const RightBubble = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 10px;
  margin-top: 8px;
  min-height: 50px;
  border-radius: 12px;
  background-color: ${colors.blue200};
  font-size: 14px;
  color: ${colors.white};
  padding: 10px 15px;
  font-weight: 500;
  position: relative;
  justify-content: flex-start;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent ${colors.blue200}; /* Adjust arrow color */
    transform: translateY(-50%);
  }
`;

const InputContainer = styled.form`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background: #eff8ff;
  flex-direction: row;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-top: 2px;
  margin-bottom: -4px;
`;

const SendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 100px;
  padding: 10px;
  background-color: #00a8ff;
  border: 2px #faeeee solid;
  cursor: pointer;
`;

const InnerRightMessageWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  max-width: 80%;
`;

const InnerLeftMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

// const SenderName = styled.p<{ white?: boolean }>`
//   font-size: 12px;
//   font-weight: 600;
//   color: ${colors.darkGrey};
//   ${(props) => (props.white ? `color: ${colors.white};` : ``)}
// `;

const ChatImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
`;

const UserWrapper = styled.div<{ left?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => (props.left ? `margin-left: 10px;` : `margin-right: 10px;`)}
`;

const UserImg = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100px;
`;

const MessageTime = styled.span<{ isLeftBubble?: boolean }>`
  color: ${({ isLeftBubble }) => (isLeftBubble ? 'gray' : 'white')};
  font-size: 12px;
`;
